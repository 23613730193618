import React, {useState, useEffect} from "react";
import axios from "axios";
import LOGO from '../../assets/img/logo.png'
import Breadcrumbs from "../../Common/breadcrumb";
import { useParams } from "react-router-dom"
function Team() {
    let APIURL = `https://admin.hotelalicenepal.com.np/api/team/all/`;
    const [Data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const { slug } = useParams(null);

    useEffect(() => {
        (async () => {
          try {
            setLoading(true);
            setError(false);
            const response = await axios.get(`${APIURL}${slug}`);
            setData(response.data.items);
            console.log("Team", response.data.items);
            setLoading(false);
          } catch (error) {
            setError(true);
            setLoading(false);
          }
        })();
      }, [slug]);
      if (loading) return  '';
      if (error) return '';
    
    
    return(
        <>
            <Breadcrumbs />
            <section className="team">
                <div className="container">
                    <div className="members">
                        <ul className="list-unstyled d-flex">
                            {
                               Data.map((value,index)=>{
                                console.log("value",value);
                                return(
                                    <>
                                    {
                                      value.team_group_id=="1"?
                                    <div className="BOD">
                                       <li key={value.id}>
                                            <div className="team-card">
                                                <div className="card-img-top">
                                                    <img src={value.featured_image?value.featured_image: LOGO} alt={value.name} />
                                                </div>
                                                <div className="card-body">
                                                    <div className="title">{value.name}</div>
                                                    <p className="post">{value.designation_name}</p>
                                                </div>
                                            </div>
                                        </li>
                                    </div>
                                    :
                                    <div className="MNG">
                                       <li key={value.id}>
                                            <div className="team-card">
                                                <div className="card-img-top">
                                                    <img src={value.featured_image?value.featured_image: LOGO} alt={value.name} />
                                                </div>
                                                <div className="card-body">
                                                    <div className="title">{value.name}</div>
                                                    <p className="post">{value.designation_name}</p>
                                                </div>
                                            </div>
                                        </li>
                                    </div>
                                    }
                                   
                                    </>
                                )
                               })
                            }
                        </ul>
                    </div>
                </div>
            </section>
        </>
    );
}

export default Team;