import React from "react";
import SwiperSlider from "./SwiperSlider";

function Events({events}) {
    return(
        <>
            <section id="4" className="events">
                <div className="container" data-aos="fade-up">
                    <div className="section-title">
                    <h2>Events</h2>
                    <p>Organize Your Events</p>
                    </div>
                    <SwiperSlider events={events} />
                </div>
            </section>
        </>
    )
}

export default Events;