import { useEffect, useState } from "react";
import React from "react";
import Breadcrumbs from "../../Common/breadcrumb";
import { Container, Row, Col } from "react-bootstrap";
import axios from "axios";
import { Link, useParams } from "react-router-dom";

function RoomList() {   
  const {slug} = useParams();
  const [data, setData] = useState([]);
  let APIURL = `https://admin.hotelalicenepal.com.np/api/rooms/all/`;
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        setError(false);
        const response = await axios.get(`${APIURL}${slug}`);
        setData(response.data.items);
        console.log("RoomDetails", response.data.items);
        setLoading(false);
      } catch (error) {
        setError(true);
        setLoading(false);
      }
    })();
  }, [slug]);
  if (loading) return  '';
  if (error) return '';

    return(
        <>
            <Breadcrumbs />
            <div className="details-room">
              <Container>
                  <Row>
                    {
                      data.map((room) =>{
                        return(
                          <>
                            <Col xs="6">
                              <div className="details">
                                <h4>{room.title}</h4>
                                <p className="description" dangerouslySetInnerHTML={{ __html: room?.description ? room?.description : '' }}></p>
                                <Link to={`${room.slug}`} className="book-a-table-btn scrollto d-lg-flex">Book Now</Link>
                              </div>
                            </Col>
                          </>
                        )
                      })
                    }
                  </Row>    
              </ Container>
            </div>
        </>
    )
}

export default RoomList;