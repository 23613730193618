import React, { useState, useEffect } from "react";
import axios from "axios";
import HomeAboutUs from "../components/HomeAboutUs";
import BookTable from "../components/BookTable";
import Specials from "../components/Specials";
import Room from "./Rooms/Room";
import Events from "../components/Events";
import ContactUs from "../components/ContactUs";
import LGallery from "../components/LightGallery";

function Home() {
  const [homeContent, setHomeContent] = useState([]);
  let APIURL = `https://admin.hotelalicenepal.com.np/api/home`;
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        setError(false);
        const response = await axios.get(APIURL);
        setHomeContent(response.data);
        // console.log("HOme____", response.data);
        setLoading(false);
      } catch (error) {
        setError(true);
        setLoading(false);
      }
    })();
  }, []);
  if (loading) return  '';
  if (error) return '';

  

  return (
    <>
      <section id="hero" className="d-flex align-items-center">
        <div
          className="container position-relative text-center text-lg-start"
          data-aos="zoom-in"
          data-aos-delay={100}
        >
          <div className="row">
            <div className="col-lg-8">
              <h1>
                Welcome to <span>Hotel Alice</span>
              </h1>
              <h2>The Wonder inn Ltd.</h2>
              <div className="btns">
                <a
                  href="#about"
                  className="btn-book animated fadeInUp scrollto"
                >
                  Read More
                </a>
              </div>
            </div>
            <div
              className="col-lg-4 d-flex align-items-center justify-content-center position-relative"
              data-aos="zoom-in"
              data-aos-delay={200}
            >
              <a
                href="https://www.youtube.com/watch?v=u6BOC7CDUTQ"
                className="glightbox play-btn"
              />
            </div>
          </div>
        </div>
      </section>
      <main id="main">
        <HomeAboutUs about={homeContent.about} />
        <Room rooms={homeContent.rooms}/>
        <Specials places={homeContent.places}/>
        <Events events={homeContent.events}/>
        <BookTable />
        {/* <Gallery gallerys={homeContent.gallerys} /> */}
        <LGallery gallerys={homeContent.gallerys} />
        <ContactUs site_settings={homeContent.site_settings}/>
      </main>
    </>
  );
}

export default Home;
