import React from "react";
import { Routes, Route } from "react-router-dom";
import Header from "../Common/Header";
import Home from "../pages/Home";
import About from "../pages/About";
import Footer from "../Common/Footer";
import SingleRoom from "../pages/Rooms/SingleRoom";
import RoomList from "../pages/Rooms/RoomList";

const MyRoutes = () => {
    
  return (
    <>
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="about-us/:slug" element={<About />} />
        {/* <Route path="rooms/:slug" element={<RoomList />} /> */}
        <Route path="rooms/:slug" element={<SingleRoom />} />
      </Routes>
      <Footer />
    </>
  );
};
export default MyRoutes;
