import { useEffect, useState } from "react";
import Myroutes from "./routes/myroutes";
import Loader from "./Common/loader";
function App() {
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    const dataFetch = () => {
      setTimeout(() => {
        setIsLoading(false);
      }, 4000);
    };
    dataFetch();
  }, []);
  
  return isLoading ? (
    <Loader />
  ):(
    <>
      <Myroutes />
    </>
  );
}

export default App;
