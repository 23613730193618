import React from 'react';

function HomeRooms({ room }) {
    return (
        <>
                <div className="box" data-aos="zoom-in" data-aos-delay={100}>
                    <div className="room-img">
                        <img src={room?.featured_image} />
                        <span>{`NPR ${room?.price}`}</span>
                    </div>
                    <div className="room-details">
                        <h4>{room.title}</h4>
                        {/* <p dangerouslySetInnerHTML={{ __html: room?.description ? room?.description : '' }}>
                        </p> */}
                        <a href={`rooms/${room?.slug}`} className="details-btn">View Details</a>
                    </div>
                </div>
        </>
    )
}

export default HomeRooms;
