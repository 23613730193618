import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";

  // let APIURL = `https://admin.hotelalicenepal.com.np/api/home/contact_msg/`;
function BookTable() {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    formState,
  } = useForm({ shouldUseNativeValidation: true });
  const [success, setSuccess] = React.useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const form = useForm();
  const onSubmit = async (formValues) => {
 
    setLoading(true);
    await axios
      .post(
        "https://admin.hotelalicenepal.com.np/api/home/booking/",{
          ...formValues,
        },
        { headers: { "content-type": "Content-type:application/json" } }
      )
      .then(({ data }) => {
        if (data?.status_code === 200) {
          alert(data.status_message);
          reset();
          setLoading(false);
          // setSuccess(true);
          // setTimeout(() => {
          //   setSuccess(false);
          // }, 3000);
        } else {
          alert(data.status_message);
          setLoading(false);
        }
      
      }).catch((error) => {
        console.log(`error${error}`);
      });
  };

  

    return(
        <section id="book-a-table" className="book-a-table" >
          <div className="container" data-aos="fade-up">
            <div className="section-title">
              <p>Online Booking</p>
            </div>
            <form
              onSubmit={handleSubmit(onSubmit)} 
            >
              <div className="row">
                <div className="col-lg-4 col-md-6 form-group">
                  <input
                    type="text"
                    className="form-control"
                    id="fullname"
                    placeholder="Your Name"
                    {...register("fullname", {required:'This is required.'})}
                  />
                  {errors.fullname && <p>Name is required.</p>}
                
                </div>
                <div className="col-lg-4 col-md-6 form-group mt-3 mt-md-0">
                  <input
                    type="email"
                    className="form-control"
                    {...register("email", {required:'This is required.', minLength:4})}
                    id="email"
                    placeholder="Your Email"
                  />
                  {errors.email && <p>Email is required and must be valid</p>}
                
                </div>
                <div className="col-lg-4 col-md-6 form-group mt-3">
                  <input
                    type="number"
                    className="form-control"
                    {...register("phone", {required:'This is required.', minLength:4})}
                    id="phone"
                    placeholder="Your Phone"
                  />
                  {errors.phone && <p>Phone number is required.</p>}
                
                </div>
                <div className="col-lg-4 col-md-6 form-group mt-3">
                  <input
                    type="date"
                    {...register("booking_date", {required:'This is required.'})}
                    className="form-control"
                    id="booking_date"
                    placeholder="Date"
                  />
                  {errors.booking_date && <p>Date is required.</p>}
                
                </div>
                <div className="col-lg-4 col-md-6 form-group mt-3">
                  <input
                    type="time"
                    className="form-control"
                    {...register("booking_time", {required:'This is required.'})}
                    id="booking_time"
                    placeholder="Time"
                  />
                  {errors.booking_time && <p>Time is required.</p>}
                
                </div>
                <div className="col-lg-4 col-md-6 form-group mt-3">
                  <input
                    type="number"
                    className="form-control"
                    {...register("no_person", {required:'This is required.'})}
                    id="no_person"
                    placeholder="# of people"
                  />
                  {errors.no_person && <p>No. of people is required.</p>}
                
                </div>
              </div>
              <div className="form-group mt-3">
                <textarea
                  className="form-control"
                  {...register("message")}
                  rows={5}
                  placeholder="Message"
                
                />
              </div>
              <div className="text-center">
                <button id="submit" type="submit">Booking</button>
                {success.submit && <p>Booking successful.</p>}
              </div>
            </form>
          </div>
        </section>
    )
}

export default BookTable;