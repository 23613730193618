import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { Link as ScrollLink, animateScroll as scroll } from 'react-scroll';

function Footer() {
    let APIURL = `https://admin.hotelalicenepal.com.np/api/menu/bottom`;
    const [Data, setData] = useState([]);
    const [setting, setSetting] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const handleSetActive = (to) => {
        console.log(to);
      };

    useEffect(() => {
        (async () => {
          try {
            setLoading(true);
            setError(false);
            const response = await axios.get(APIURL);
            setData(response.data.menu);
            console.log("Footer", response.data)
            setSetting(response.data.site_settings);
            setLoading(false);
          } catch (error) {
            setError(true);
            setLoading(false);
          }
        })();
    }, []);
    if (loading) return  '';
    if (error) return '';

    return (
        <>
            <footer id="footer">
                <div className="footer-top">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-4 col-md-6">
                                <div className="footer-info">
                                    <h3>Hotel Alice</h3>
                                    <p>
                                        {setting.address}
                                    </p>
                                    <p>
                                        <strong>Phone:</strong> {setting.mobile}
                                    </p>
                                    <p>
                                        <strong>Email:</strong> {setting.email}
                                    </p>
                                    <div className="social-links mt-3">
                                        <a href={setting.twitter} className="twitter">
                                            <i className="bi bi-twitter" />
                                        </a>
                                        <a href={setting.facebook} className="facebook">
                                            <i className="bi bi-facebook" />
                                        </a>
                                        <a href={setting.instagram} className="instagram">
                                            <i className="bi bi-instagram" />
                                        </a>
                                        <a href={setting.linked_in} className="linkedin">
                                            <i className="bi bi-linkedin" />
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 footer-links">
                                <h4>Useful Links</h4>
                                <ul>
                                    {
                                        Data?.length === 0 ? "No Content Found" :
                                            Data?.map((menu) => {
                                                return (
                                                    <li key={menu.id}>
                                                        <i className="bi bi-chevron-right" />
                                                        {/* <a href={footer.slug}>{footer.title}</a> */}
                                                        <ScrollLink
                                                            activeClass="active" 
                                                            to={menu.id} 
                                                            spy={true} 
                                                            smooth={true} 
                                                            offset={-100} 
                                                            duration={500} 
                                                            onSetActive={handleSetActive}
                                                            >
                                                            {menu.title}
                                                        </ScrollLink> 
                                                    </li>
                                                );
                                            })
                                    }

                                </ul>
                            </div>
                            <div className="col-lg-4 col-md-6 footer-newsletter">
                                <h4>Our Newsletter</h4>
                                <p>
                                    Get monthly updates on the new discounts, offers and programs hosted by our hotel.
                                </p>
                                <form action="" method="post">
                                    <input type="email" name="email" />
                                    <input type="submit" defaultValue="Subscribe" />
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="copyright">
                                © Copyright{" "}
                                <strong>
                                    <span>{setting.site_name}</span>
                                </strong>
                                . All Rights Reserved
                            </div>
                        </div>
                        <div className="col-md-6">    
                            <div className='powered_by'>
                                <p>Powered By <a href="https://nyatapol.com/" target='__blank'><strong>Nyatapol Technology</strong></a></p>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
            {/* <div id="preloader" /> */}
            <a href="#" className="back-to-top d-flex align-items-center justify-content-center">
                <i className="bi bi-arrow-up-short" />
            </a>
        </>

    )
}

export default Footer
