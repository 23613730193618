import React from "react";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css/bundle';
import { Pagination, Navigation } from 'swiper/modules';
// import 'swiper/css/pagination';
import HomeRooms from "../../components/HomeRooms";

function Room({rooms}) {
    return(
        <section id="why-us" className="why-us">
          <div className="container" data-aos="fade-up">
            <div className="section-title">
              <p>Our Wonderful Rooms</p>
            </div>
            <Swiper  
              breakpoints={{
                0: {
                  slidesPerView: 1,
                  spaceBetween:30,
                },
                450: {
                  slidesPerView: 2,
                  spaceBetween:20,
                },
                991: {
                  slidesPerView: 3,
                },
              }}
              spaceBetween={30}
              slidesPerView={3}
              pagination={true} 
              navigation={true}
              // cssMode={true}
              modules={[Pagination, Navigation]}
            >
              {
                rooms?.map((room) => {
                  return (
                    // <HomeRooms key={room?.id} room={room} />
                    
                      <SwiperSlide  key={room?.id}>
                        <HomeRooms room={room} />  
                      </SwiperSlide>  
                    
                    );
                })
              }
            </Swiper>
          </div>
        </section>
    )
}

export default Room;