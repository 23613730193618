import React from 'react'
import { Link } from 'react-router-dom'

function HomeAboutUs({ about }) {

    return (
        <>
            <section id="about" className="about">
                <div className="container" data-aos="fade-up">
                    <div className="row">
                        <div className="col-lg-6 order-1 order-lg-2 d-flex justify-content-center" data-aos="zoom-in" data-aos-delay={100}>
                        <div className="about-img">
                            <img src={about?.featured_image} alt={about?.title} />
                        </div>
                        </div>
                        <div className="col-lg-6 pt-4 pt-lg-0 order-2 order-lg-1 content">
                            <h3 id='aboutTitle'>{about?.title}</h3>
                            <p dangerouslySetInnerHTML={{ __html: about?.description ? about?.description : '' }}>

                            </p>
                            <div className="btns">
                                <Link 
                                    to="about-Us/company-profile"
                                    className="btn-book red-color animated fadeInUp scrollto"
                                >
                                    Read More
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default HomeAboutUs
