import React from 'react';
import LightGallery from 'lightgallery/react';  
import 'lightgallery/css/lightgallery.css';
import 'lightgallery/css/lg-zoom.css';
import 'lightgallery/css/lg-thumbnail.css';
import lgThumbnail from 'lightgallery/plugins/thumbnail';
import lgZoom from 'lightgallery/plugins/zoom';

function LGallery({gallerys}) {
    const onInit = () => {
        console.log('lightGallery has been initialized');
    };
    return (
        <>
            <section id='5' className='gallery'>
                <div className="container" data-aos="fade-up">
                    <div className="section-title">
                    <h2>Gallery</h2>
                    <p>Some photos from Hotel Alice</p>
                    </div>
                </div>
                <div className='container'>
                    <LightGallery
                        onInit={onInit}
                        speed={500}
                        plugins={[lgThumbnail, lgZoom]}
                    >
                        {
                            gallerys?.length === 0 ? 'No Content Found' :
                            gallerys?.map((gal, i) => {
                                return (
                                        <a
                                            key={i}
                                            href={gal.featured_image}
                                            className="gallery-item"
                                        >
                                            <img
                                            src={gal.featured_image}
                                            alt={gal.title}
                                            className="img-fluid"
                                            />
                                        </a>
                                );
                            })
                        }
                    </LightGallery>
                </div>
            </section>            
        </>
    );
}

export default LGallery;