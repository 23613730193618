import React, { useState, useEffect } from "react";
import logo from "../assets/img/logo.png";
import "bootstrap/dist/js/bootstrap.bundle.min";
import "bootstrap/dist/css/bootstrap.min.css";
import 'bootstrap-icons/font/bootstrap-icons.css';
import axios from "axios";
import { Button, Navbar, NavDropdown, Nav, Container } from 'react-bootstrap';
import { Link } from "react-router-dom";
import { Link as ScrollLink, animateScroll as scroll } from 'react-scroll';

function Header() {
  let APIURL = `https://admin.hotelalicenepal.com.np/api/menu/top`;
  const [Data, setData] = useState([]);
  const [setting, setSetting] = useState([]);
  const [menuOpen, setMenuOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [scrolling, setScrolling] = useState(false);

  // Function to handle the activation of a link.
  const handleSetActive = (to) => {
    console.log(to);
  };

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        setError(false);
        const response = await axios.get(APIURL);
        setData(response.data.items);
        setSetting(response.data.site_settings);
        // console.log("HOme____", response.data);
        setLoading(false);
      } catch (error) {
        setError(true);
        setLoading(false);
      }
    })();

    const handleScroll = () => {
      if (window.scrollY > 0) {
        setScrolling(true);
      } else {
        setScrolling(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
    
  }, []);
  if (loading) return  '';
  if (error) return '';
  const profile = ['company-profile', 'message-from-ceo', 'board-of-directors', 'management-team',]
  const rooms = ['ac-room', 'non-ac-room']
  return (
    <>
      <header>
        <div id="topbar" className={scrolling ? 'hidden' : ''}>
          <div className="container d-flex justify-content-center justify-content-md-between">
            <div className="contact-info d-flex align-items-center">
              <i className="bi bi-phone d-flex align-items-center">
                <span>
                  {
                    setting.mobile ? `Need Support ? ${setting.mobile}` : ''
                  }
                </span>
              </i>
              <i className="bi bi-envelope d-flex align-items-center ms-4">
                <span>{
                  setting.mobile ? ` ${setting.email}` : ''
                }
                </span>
              </i>
            </div>
            <div className="languages d-none d-md-flex align-items-center">
              <ul>
                <li>
                  <a href={setting.twiter} className="twitter">
                    <i className="bi bi-twitter" />
                  </a>
                </li>
                <li>
                  <a href={setting.facebook} className="facebook">
                    <i className="bi bi-facebook" />
                  </a>
                </li>
                <li>
                  <a href={setting.instragram} className="instagram">
                    <i className="bi bi-instagram" />
                  </a>
                </li>
                <li>
                  <a href={setting.googleplus} className="google-plus">
                    <i className="bi bi-skype" />
                  </a>
                </li>
                <li>
                  <a href={setting.linked_in} className="linkedin">
                    <i className="bi bi-linkedin" />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div id="bottombar" className={scrolling ? 'fixed' : ''}>
          <Navbar expand="lg">
            <Container>
              <Navbar.Brand className="logo me-auto me-lg-0">
                <Link to="/">
                  <img src={setting.logo ? setting.logo : logo} alt={setting.site_name} className="img-fluid" />
                </Link>
              </Navbar.Brand>
              <Navbar.Toggle aria-controls="basic-navbar-nav" />
              <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="m-auto">
                  {Data.map((menu) =>{
                    return (
                    <>
                    {
                      menu?.child === undefined? 
                      <ScrollLink
                        activeClass="active" 
                        to={`${menu.slug}`}
                        spy={true} 
                        smooth={true} 
                        offset={-100} 
                        duration={500} 
                        onSetActive={handleSetActive}
                        >
                          {menu.title} 
                      </ScrollLink>   
                      :
                      <NavDropdown title={menu.title} id="basic-nav-dropdown">
                        {
                          menu?.child?.map((child, index) => {

                          switch (true) {
                            case profile.includes(child.slug):
                              return(
                                <NavDropdown.Item>
                                  <Link key={child.id} to={`about-us/${child.slug}`}>
                                    {child.title}
                                  </Link>
                                </NavDropdown.Item>                             
                              );
                            default:
                              return(
                                <NavDropdown.Item>
                                  <Link key={child.id} to={`rooms/${child.slug}`}>
                                    {child.title}
                                  </Link>
                                </NavDropdown.Item> 
                              );
                          }                          
                          })
                        }
                        </NavDropdown>
                    }
                    </>
                      
                    )
                  }
                    
                  )}
                </Nav>
                <ScrollLink
                  className="book-a-table-btn scrollto d-lg-flex" 
                  activeClass="active" 
                  to="book-a-table"
                  spy={true} 
                  smooth={true} 
                  offset={-100} 
                  duration={500} 
                  onSetActive={handleSetActive}
                  >
                    Online Booking 
                </ScrollLink>
              </Navbar.Collapse>
            </Container>
          </Navbar>
        </div>
      </header>
    </>
  );
}

export default Header;
