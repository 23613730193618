import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Breadcurumbs from "../../Common/breadcrumb";
const CompanyProfile = ({companyprofile}) => {
  console.log('____________________',companyprofile);
  return (
    <>
     <Breadcurumbs />
      <div className="detail-section">
        <section>
          <Container>
              <Row>
                  <Col lg={{span:6, order:1}} xs={{span:12, order:2}} >
                      <h3 id="subTitle" style={{color:'#eb1c24', marginBottom:'20px'}}>{companyprofile?.title}</h3>
                      <p style={{textAlign: 'justify'}} dangerouslySetInnerHTML={{ __html: companyprofile?.description ? companyprofile?.description : '' }}></p>
                  </Col>
                  <Col lg={{span:6, order:2}} xs={{span:12, order:1}} className="d-flex justify-content-center mb-md-4">
                  <div className="about-img">
                      <img src={companyprofile?.featured_image} alt={companyprofile?.title} />
                  </div>
                  </Col>
              </Row>
          </Container>  
        </section> 
      </div>
    </>
  )
};
  
export default CompanyProfile;