import React from "react";
import { Element } from "react-scroll";

function ContactUs({site_settings}) {
    return(
      <Element name="contact-us">
        <section id="6" className="contact">
          <div className="container" data-aos="fade-up">
            <div className="section-title">
              <p>Contact Us</p>
            </div>
          </div>
          <div className="container" data-aos="fade-up">
            <div className="row mt-5">
              <div className="col-lg-4">
                <div className="info">
                  <div className="address">
                    <i className="bi bi-geo-alt" />
                    <h4>Location:</h4>
                    <p>{site_settings?.address}</p>
                  </div>
                  <div className="open-hours">
                    <i className="bi bi-clock" />
                    <h4>Open Hours:</h4>
                    <p>
                      Monday-Saturday:
                      <br />
                      {`${site_settings?.opening_time} - ${site_settings?.closing_time}`}
                    </p>
                  </div>
                  <div className="email">
                    <i className="bi bi-envelope" />
                    <h4>Email:</h4>
                    <p>{site_settings?.email}</p>
                  </div>
                  <div className="phone">
                    <i className="bi bi-phone" />
                    <h4>Call:</h4>
                    <p>{site_settings?.mobile}</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-8 mt-5 mt-lg-0">
                <div data-aos="fade-up">
                  <iframe
                    style={{ border: 0, width: "100%", height: 350 }}
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1769654.0176412193!2d79.7113037109375!3d29.969211659636663!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39a49f61450ca239%3A0xeee5136e5b4ca071!2sHotel%20Alice%20The%20Wonder%20Inn%20Ltd.!5e0!3m2!1sen!2snp!4v1676631861476!5m2!1sen!2snp"
                    width={600}
                    height={450}
                    allowFullScreen=""
                    loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade"
                  ></iframe>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Element>
    )
}

export default ContactUs;