import { useEffect, useState } from "react";
import React from "react";
import Breadcrumbs from "../../Common/breadcrumb";
import { Container, Row, Col } from "react-bootstrap";
import axios from "axios";
import { useParams, Link } from "react-router-dom";
import BookTable from "../../components/BookTable";

function SingleRoom() {   
  const {slug} = useParams();
  const [Data, setData] = useState([]);
  let APIURL = `https://admin.hotelalicenepal.com.np/api/rooms/detail/`;
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        setError(false);
        const response = await axios.get(`${APIURL}${slug}`);
        setData(response.data.detail);
        console.log('RoomDetails', response.data.detail)
        setLoading(false);
      } catch (error) {
        setError(true);
        setLoading(false);
      }
    })();
  }, [slug]);
  if (loading) return  '';
  if (error) return '';

    return(
        <>
            <Breadcrumbs />
            <div className="details-room">
              <Container>
                <div className="details mb-5">
                  <div className="row align-items-top">
                    <div className="col-md-5">
                      {/* <h4>{Data.title}</h4> */}
                      <p class="price">Price: <span>{Data.Min_Balance}</span> per night</p>
                      <img src={Data.featured_image} alt={Data.title} />
                    </div>
                    <div className="col-md-7">
                      <p className="description" dangerouslySetInnerHTML={{ __html: Data?.Description ? Data?.Description : '' }}></p>
                    </div>                    
                  </div>
                </div> 
              </ Container>
              <BookTable />
            </div>
        </>
    )
}

export default SingleRoom;