import React from "react";
import { useEffect, useRef } from "react";

function Specials({places}) {
    const scrollToElementRef = useRef();
    useEffect(() => {
        if (scrollToElementRef.current) {
        scrollToElementRef.current.scrollIntoView({ block: 'end', behavior: 'smooth' });
        }
    }, []);

    
    
    return(
        <section id="3" className="specials">   
            <div className="container" data-aos="fade-up">
                <div className="section-title">
                    <p>Places of Interest</p>
                </div>
                <div className="row" data-aos="fade-up" data-aos-delay={100}>
                    <div className="col-lg-3">
                        <ul className="nav nav-tabs flex-column" >
                            {
                            places?.map((place, i) => {
                                return (
                                <li className="nav-item" key={ i }>
                                    <a
                                    className={`nav-link ${i === 0 ? 'active show' : 0}`}
                                    data-bs-toggle="tab"
                                    href={`#tab-${place?.id}`}
                                    >
                                    {place.parent}
                                    </a>
                                </li>
                                );
                            })
                            }
                        </ul>
                    </div>
                    <div className="col-lg-9 mt-5 mt-lg-0">
                    <div className="tab-content">
                        {
                        places?.map((destinationPlaces, j) => {

                            return (
                            <div key={j} className={`tab-pane ${j === 0 ? 'active show' : ''}`} id={`tab-${destinationPlaces?.id}`}>
                                {
                                destinationPlaces.child.length === 0 ?
                                    'No Content Found'
                                    :
                                    destinationPlaces.child.map((ch) => {
                                    return (
                                        <div className="row">
                                        <div className="col-md-7 details order-2 order-lg-1">
                                            <h3>{ch?.title}</h3>
                                            <p dangerouslySetInnerHTML={{ __html: ch?.description ? ch?.description : '' }}>
                                            </p>
                                        </div>
                                        <div className="col-md-5 text-center order-1 order-lg-2">
                                            <img
                                            src={ch?.featured_image}
                                            alt={ch?.title}
                                            className="img-fluid"
                                            />
                                        </div>
                                        </div>
                                    );
                                    })
                                }
                            </div>
                            );
                        })
                        }


                    </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Specials;