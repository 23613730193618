import React from 'react';
import { Rings } from 'react-loader-spinner';
import favIcon from '../assets/img/favicon.png'

const Loader = () => {
    return (
      <>
        <div className="loader">
          <div className="loader-wrapper">
            <img src={favIcon} alt="icon" />
            <Rings
              visible={true}
              height="80"
              width="80"
              color="#eb1c24"
              ariaLabel="rings-loading"
              wrapperStyle={{}}
              wrapperClass=""
            />
          </div>
        </div>
      </>
    );
  };

export default Loader;