import React, { useState, useEffect } from "react";
import axios from "axios";
import CompanyProfile from "./CompanyProfile"
import Team from "./Team"
import { useParams } from "react-router-dom"

const About=()=>{
   
    const [homeContent, setHomeContent] = useState([]);
    let APIURL = `https://admin.hotelalicenepal.com.np/api/content/detail/`;
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const { slug } = useParams(null);
    useEffect(() => {
      (async () => {
        try {
          setLoading(true);
          setError(false);
          const response = await axios.get(`${APIURL}${slug}`);
          setHomeContent(response.data.detail);
          console.log("HOme____ssss", response.data.detail);
          setLoading(false);
        } catch (error) {
          setError(true);
        }
      })();
    }, [slug]);
    if (loading) return  '';
    if (error) return '';

    const contents = ['company-profile', 'message-from-ceo']
    const team = ['board-of-directors', 'management-team',]
    switch (true) {
        case contents.includes(slug):
            return (
                <CompanyProfile companyprofile={homeContent}/>
            );
        case team.includes(slug):
            return (
                <div><Team  /></div>
            );
        default:
            return (
                <div>Exception</div>
            );
    }
}
export default About