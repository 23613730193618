import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import 'swiper/css/bundle';
import 'swiper/css/pagination';

function SwiperSlider({ events }) {
    return (
        <Swiper
            spaceBetween={10}
            slidesPerView={1}
            onSlideChange={() => console.log('slide change')}
            onSwiper={(swiper) => console.log(swiper)}
        >
            {
                events?.map((event) => {
                    return (
                        <SwiperSlide key={event.id}>
                            <div className="row">
                                <div className="col-lg-4  col-md-6">
                                    <img
                                        src={event?.featured_image}
                                        className="img-fluid"
                                        alt={event?.title}
                                    />
                                </div>
                                <div className="col-lg-8 col-md-6 pt-4 pt-lg-0 content">
                                    <h3>{event?.title}</h3>
                                    <div dangerouslySetInnerHTML={{ __html: event?.description ? event?.description : 'No Contnet' }}>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                    );
                })
            }


        </Swiper>
    );
}

export default SwiperSlider
